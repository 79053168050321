<template>
	<div class="page dopay_page">
		<div class="block30"></div>
		<div class="w1200">
			<div class="conbox">
				<div class="titlebox fontsize24">商品信息</div>
				<div class="goodsbox flexrow">
					<div class="icon"><img class="img100" :src="classObj.imagesUrl"></div>
					<div class="text1box">
						<div class="text1 fontsize20 clamp2">{{classObj.title}}</div>
						<div class="text2 fontsize14 clamp2">{{classObj.introduction}}</div>
					</div>
					<div class="danprice fontsize16">￥{{classObj.schedulePrice}}</div>
				</div>
				<div class="linebox"></div>
				<div class="bottombox">
					<div class="titlename fontsize16">在线支付</div>
					<div class="paytype">
						<div class="itemobj" :class="{'active':payType==0}" @click="handleType(0)">
							<div class="icon"><img class="img100" src="../assets/static/weixin.png"></div>
							<div class="text fontsize14">微信支付</div>
						</div>
						<div class="itemobj" :class="{'active':payType==1}" @click="handleType(1)">
							<div class="icon"><img class="img100" src="../assets/static/zfb.png"></div>
							<div class="text fontsize14">支付宝</div>
						</div>
					</div>
					<div class="pricebox">
						<div class="text1 fontsize14">共1件，商品总金额：￥{{classObj.schedulePrice}}</div>
						<div class="text2 fontsize20">应付：￥{{classObj.schedulePrice}}</div>
						<div class="btn1 fontsize14" @click="submitOrder">提交订单</div>
					</div>
				</div>
				<div class="block58"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 支付页面 -->
		<doPay ref="refdoPay" :is-show="isShowPay" :payType="payType" :min-price="classObj.schedulePrice"
			@clickpayclose="clickpayclose" />
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import doPay from '@/components/doPay/doPay.vue';
	export default {
		components: {
			doPay
		},
		data() {
			return {
				payType: 0, //下标 0是微信
				uid: "", //目录课程ID
				chapterId: null, //指定章节
				classObj: {}, //课程目录详情
				isShowPay: false, // 支付弹窗
			};
		},
		mounted: function() {
			if (!this.isLogin) {
				this.$message.error("请先返回首页登录！")
			}
			if (this.$route.query && this.$route.query.uid) {
				this.uid = this.$route.query.uid
				if (this.$route.query.chapterId) {
					this.chapterId = this.$route.query.chapterId
				}
				//获取我目录详情
				this.getScheduleById()
			} else {
				this.$message.error("参数丢失，请返回")
			}
		},
		computed: {
			...mapState(['userInfo', 'isLogin', 'sysRole']),
		},
		methods: {
			submitOrder() {
				var _this = this
				if (!this.isLogin) {
					this.$message.error("请先登录账号~")
					return;
				}
				var params = {
					payType: this.payType, //支付类型 MA小程序 WEB网页（失效） CASH货到付款 POINT积分
				}
				params["scheduleId"] = this.uid
				params["userUuid"] = this.userInfo.uuid
				showLoading();
				this.$http.post("pay/gen-orders/CreateOrders", params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						//继续支付
						_this.dopay(res)
					} else {
						hideLoading();
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			// 发动支付
			dopay(res) {
				var _this = this
				_this.isShowPay = true
				// outTradeNo
				// : 
				// "1711793366039733734"
				// payMsg
				// : 
				// "weixin://wxpay/bizpayurl?pr=z4dmnyhzz"
				// var payMsg = {}
				// if(res.data.payMsg){
				// 	payMsg = JSON.parse(res.data.payMsg)
				// }
				setTimeout(function() {
					_this.$refs.refdoPay.creatQrCode(res.data.payMsg)
				}, 800)
				hideLoading();
			},
			// 取消支付
			clickpayclose() {
				var _this = this
				this.$confirm('此操作将关闭支付弹窗，请确定已经支付成功，关闭后，将会跳转回课程详情页面，是否继续?', '提示', {
						confirmButtonText: '确定支付成功',
						cancelButtonText: '已取消支付',
						type: 'warning'
					}).then(() => {
						_this.isShowPay = false
						//普通订单
						_this.$router.push({
							path: '/class',
							query: {
								uid: _this.uid
							}
						})
					})
					.catch(() => {
						_this.isShowPay = false
						_this.$message({
							type: 'info',
							message: '已取消'
						})
					})
			},
			//下标
			handleType(type) {
				if (type == 1) {
					this.$message.error("暂不支持支付宝支付")
					return false;
				}
				this.payType = type
			},
			//获取我目录详情
			getScheduleById() {
				var _this = this
				//需要登录
				if (this.isLogin) {
					var params = {}
					params["scheduleId"] = this.uid
					params["userUuid"] = this.userInfo.uuid
					this.$http.post('TrainModule/train-user-schedule2/userGetScheduleProgress', params).then(function(
					res) {
						//数据处理
						if (res.code == 200) {
							if (res.data.schedule) {
								_this.classObj = res.data.schedule
								if(!_this.classObj.trainUserSchedule){
									_this.$message.error("请先加入课程学习")
									_this.$router.push({
										path: '/class',
										query: {
											uid: _this.uid
										}
									})
								}
							}
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				}
			},

		}
	};
</script>
<style lang="scss" scoped></style>